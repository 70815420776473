// import Vue from "vue";
import router from "./router";
// import store from './store'
// import "./plugins/element.js";
import axios from "axios";
import VueAxios from "vue-axios";

import { Header, Button, Progress, Main, DatePicker } from "element-ui";

import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

locale.use(lang);

// import "element-ui/lib/theme-chalk/index.css";
// import "element-ui/lib/theme-chalk/display.css";
Vue.use(Header);
Vue.use(Button);
Vue.use(Progress);
Vue.use(Main);
Vue.use(DatePicker);
import App from "./App.vue";
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
new Vue({
  router,
  // store,
  render: (h) => h(App),
}).$mount("#app");
